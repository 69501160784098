<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <DeliverySummary :order="order" :file="file" />
      <template #overlay>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
            variant="primary"
          ></b-spinner>
          <h3 class="mt-3">Loading... Please wait.</h3>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { DeliverySummary } from '@/components/CharteredTruck'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    DeliverySummary
  },
  data() {
    return {
      isLoading: false,
      order: {},
      file: ''
    }
  },
  created() {
    this.isLoading = true
    this.fetchOrders(this.$route.params.id)
  },
  methods: {
    async fetchOrders(id) {
      this.$http.get(`chartered_order?id=${id}`).then((response) => {
        if (response) {
          this.order = response.data
          if (this.order.quote) {
            if (this.order.quote.document) {
              const url = new URL(this.order.quote.document)
              this.file = url.pathname.split('/').pop()
            }
          }
        }
        this.isLoading = false
      })

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
